import React, { lazy, Suspense } from "react";

import {
  IoServer,
  IoShapes,
  IoStorefront,
  IoList,
  IoBagHandle,
  IoPeople,
} from "react-icons/io5";
import CategoryMenuSkeleton from "components/category/CategoryMenuSkeleton";

const Auth = lazy(() => import("pages/Auth"));
const Home = lazy(() => import("pages/Home"));
const Products = lazy(() => import("pages/Products"));
const Categories = lazy(() => import("pages/Categories"));
const Lot = lazy(() => import("pages/Lot"));
const Orders = lazy(() => import("pages/Orders"));
const Staff = lazy(() => import("pages/Staff"));

const routes = [
  {
    name: "Auth",
    path: "/auth",
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth />
      </Suspense>
    ),
    protected: false,
    hideFromNavBar: true,
  },
  {
    name: "Dashboard",
    path: "/",
    icon: IoShapes,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Home />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin"],
  },
  {
    name: "Inventory",
    path: "/inventory",
    icon: IoStorefront,
    component: (
      <Suspense fallback={<CategoryMenuSkeleton />}>
        <Products />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin", "Moderator"],
  },
  {
    name: "Categories",
    path: "/categories",
    icon: IoList,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Categories />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin", "Moderator"],
  },
  {
    name: "Orders",
    path: "/orders",
    icon: IoBagHandle,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Orders />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin", "Moderator"],
  },
  {
    name: "Staff",
    path: "/staff",
    icon: IoPeople,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Staff />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin"],
  },
  {
    name: "Records",
    path: "/records",
    icon: IoServer,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <Lot />
      </Suspense>
    ),
    protected: true,
    allowedRoles: ["Super Admin"],
  },
];

export default routes;
