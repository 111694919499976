import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { IconContext } from "react-icons";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import {
  ChakraProvider,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { ColorModeScript } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { AuthProvider, AuthContext } from "contexts/auth.context";
import SideNav from "components/common/nav/SideNav";
import RouteSwitch from "routes/RouteSwitch";
import useUser from "hooks/useUser";
import "styles/main.css";
import "styles/dateRangePicker.css";
import "styles/calendar.css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
});

const overrides = {
  fonts: {
    body: "Inter, system-ui, sans-serif",
    heading: "Inter, system-ui, sans-serif",
  },
  initialColorMode: "dark",
};

const theme = extendTheme(overrides);
const queryClient = new QueryClient();

function Main() {
  const { authToken, setUserInfo, logout } = useContext(AuthContext);
  const { data, isFetching, error } = useUser(false, authToken);
  const color = useColorModeValue("purple.500", "purple.400");

  useEffect(() => {
    setUserInfo((prev) => data ?? prev);
  }, [data, setUserInfo, authToken]);

  useEffect(() => {
    if (error?.status === 403 || error?.status === 401) {
      console.log("XXX===ERROR===XXX");
      console.log(error);
      logout();
    }
  }, [error, logout]);

  if (isFetching)
    return (
      <Flex
        direction="column"
        wrap="wrap"
        minHeight="100vh"
        justify="center"
        align="center"
        textAlign="center"
        p={3}
      >
        <Heading size="3xl" color={color} pb={6}>
          Fansthetic
        </Heading>
        <Heading>Loading awesomeness...</Heading>
      </Flex>
    );

  return (
    <>
      <SideNav>
        <RouteSwitch />
        {!authToken && <Redirect to="/auth" />}
      </SideNav>
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <IconContext.Provider value={{ size: "1.3em" }}>
          <AuthProvider>
            <Router>
              <Main />
            </Router>
          </AuthProvider>
        </IconContext.Provider>
      </ChakraProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
