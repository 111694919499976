function save(name, data) {
  try {
    localStorage.setItem(name, JSON.stringify(data));
  } catch (error) {
    console.log("XXX === error on LocalstorageUtils.save === XXX");
    console.log(error);
    return null;
  }
}

function get(name) {
  try {
    const val = localStorage.getItem(name);
    return val ? JSON.parse(val) : null;
  } catch (error) {
    console.log("XXX === error on LocalstorageUtils.get === XXX");
    console.log(error);
    return null;
  }
}

function remove(name) {
  try {
    return localStorage.removeItem(name);
  } catch (error) {
    console.log("XXX === error on LocalstorageUtils.remove === XXX");
    console.log(error);
    return null;
  }
}

const localstorageUtils = { save, get, remove };

export default localstorageUtils;
