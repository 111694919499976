import axios from "axios";

import localstorageUtils from "./localstorage";
import config from "../config";

function get(token) {
  if (token)
    return axios.create({
      baseURL: config.BASE_URL,
      headers: {
        Authorization: `Token ${token}`,
      },
    });

  return axios.create({ baseURL: config.BASE_URL });
}

function getCustomConfig(config, token) {
  if (
    config.url === "auth/login/" ||
    config.url === "auth/password/reset/" ||
    config.url === "auth/password/reset/confirm/"
  ) {
    return config;
  } else {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
}

async function handleResponse(res) {
  // console.log("XXXXXX ====== DATA ====== XXXXXX");
  // console.log(res.data);
  if (res?.data?.response) return Promise.resolve(res.data.response);
  return Promise.resolve(res.data);
}

async function handleError(err) {
  // console.log("XXXXXX ====== FUCK ====== XXXXXX");
  // console.log(err.response.data);

  if (!err?.response?.data) return Promise.reject(err);
  return Promise.reject({
    status: err.response.data.status_code,
    message: err.response.data.message,
  });
}

function createAuthorizedInstance() {
  const ax = axios.create({ baseURL: config.BASE_URL });
  const token = localstorageUtils.get("token");

  ax.interceptors.response.use(
    (response) => handleResponse(response),
    (err) => handleError(err)
  );

  // return the instance with only base URL if there's no token
  if (!token) {
    return ax;
  }

  // return the instance with auth token for specific urls
  ax.interceptors.request.use(
    (config) => getCustomConfig(config, token),
    (err) => {
      console.log(err);
      return Promise.reject(err);
    }
  );

  return ax;
}

const axiosUtils = { get, createAuthorizedInstance };

export default axiosUtils;
