import React, { useContext } from "react";
import {
  Flex,
  Tooltip,
  IconButton,
  useColorMode,
  Button,
} from "@chakra-ui/react";
import { FiSun, FiMoon, FiLogOut } from "react-icons/fi";

import { AuthContext } from "contexts/auth.context";

export default function NavUtils() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { authToken, logout } = useContext(AuthContext);

  return (
    <Flex
      direction={{ base: "row", lg: "column" }}
      w="100%"
      align="center"
      justify="center"
      position="absolute"
      left={0}
      bottom={0}
      py={6}
    >
      <Tooltip
        label="Toggle theme"
        aria-label="Toggle theme"
        placement="right"
        hasArrow
      >
        <IconButton
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <FiMoon /> : <FiSun />}
        />
      </Tooltip>

      {authToken && (
        <>
          <Tooltip
            label="Log out"
            aria-label="Log out"
            placement="right"
            hasArrow
          >
            <IconButton
              display={{ base: "none", lg: "flex" }}
              mt={3}
              icon={<FiLogOut />}
              onClick={logout}
            />
          </Tooltip>

          <Button
            display={{ lg: "none", xl: "none" }}
            colorScheme="red"
            onClick={logout}
            ml={3}
          >
            Logout
          </Button>
        </>
      )}
    </Flex>
  );
}
