const config = {
  SITE_NAME: "Fansthetic",
  SITE_DATA: {
    email: "fansthetic@gmail.com",
    facebook: "fb.com/fansthetic",
    instagram: "fansthetic_af",
    contact_no: "+8801714456327",
  },
  BASE_URL: process.env.REACT_APP_BASE_URL,
};

export default config;
