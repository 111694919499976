import React, { useContext } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

import NavItem from "./NavItem";
import { AuthContext } from "contexts/auth.context";
import routes from "routes";

export default function NavItems({ closeMenu }) {
  const { authToken, userInfo } = useContext(AuthContext);
  const filteredRoutes = routes.filter((route) => {
    const hiddenRoute = route.hideFromNavBar;
    const protectedRoute = route.protected && !authToken;
    const hasPermission =
      route.allowedRoles && route.allowedRoles?.length > 0
        ? route.allowedRoles?.includes(userInfo?.user_type)
        : true;

    const isVisible = !hiddenRoute && !protectedRoute && hasPermission;

    return isVisible;
  });

  if (!filteredRoutes || filteredRoutes.length === 0) return <></>;

  return (
    <AnimateSharedLayout type="crossfade" transition={{ duration: 0.1 }}>
      <motion.ul
        initial="closed"
        animate="open"
        variants={{
          open: {
            transition: { staggerChildren: 0.1 },
          },
          closed: {
            transition: { staggerChildren: 0.1, staggerDirection: -1 },
          },
        }}
      >
        {filteredRoutes.map((route, idx) => {
          return (
            <NavItem
              key={idx}
              title={route.name}
              to={route.path}
              icon={route.icon}
              closeMenu={closeMenu}
            />
          );
        })}
      </motion.ul>
    </AnimateSharedLayout>
  );
}
