import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  IconButton,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import { FiSun, FiMoon, FiMenu } from "react-icons/fi";

import NavItems from "./NavItems";
import NavUtils from "./NavUtils";
import config from "config";

function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        display={{ base: "none", lg: "block" }}
        h="100vh"
        w="5.5rem"
        minWidth="5.5rem"
        overflowY="scroll"
        position="sticky"
        top={0}
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        borderRightWidth={1}
      >
        <NavItems closeMenu={onClose} />
        <NavUtils />
      </Box>

      <Box
        display={{ base: "flex", lg: "none" }}
        w="100vw"
        p={6}
        align="center"
      >
        <Flex align="center" my={1}>
          <ChakraLink
            as={Link}
            to="/"
            fontSize={24}
            fontWeight="900"
            color="purple.500"
            _hover={{ textDecoration: "none" }}
          >
            {config.SITE_NAME}
          </ChakraLink>
        </Flex>
        <IconButton
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <FiMoon /> : <FiSun />}
          ml="auto"
        />
        <IconButton onClick={onOpen} icon={<FiMenu />} ml={2} />
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay
            sx={{
              background: "rgba(0,0,0,.4)",
              transformStyle: "preserve-3d",
              backdropFilter: "blur(6px)",
            }}
          >
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader></DrawerHeader>

              <DrawerBody>
                <NavItems closeMenu={onClose} />
              </DrawerBody>

              <DrawerFooter>
                <NavUtils />
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </>
  );
}

export default function SideNav({ children }) {
  return (
    <Flex direction={{ base: "column", lg: "row" }} w="100vw">
      <Navbar />
      <Box
        w={{
          base: "100%",
          lg: "calc(100vw - 6rem)",
        }}
      >
        {children}
      </Box>
    </Flex>
  );
}
