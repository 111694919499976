import { useQuery } from "react-query";

import { userServices } from "../services/user.service";

export default function useUser(refetchOnWindowFocus = true, authToken) {
  return useQuery(["user", authToken], userServices.get, {
    refetchOnWindowFocus: refetchOnWindowFocus,
    retry: 0,
  });
}
