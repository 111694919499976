import axiosUtils from "utils/axiosInstance";

const get = async () => {
  const ax = axiosUtils.createAuthorizedInstance();

  return ax.get("auth/user/");
};

export const userServices = {
  get,
};
