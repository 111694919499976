import React, { createContext, useState } from "react";

import localstorageUtils from "../utils/localstorage";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = ({ children }) => {
  const persistedToken = localstorageUtils.get("token");

  const [authToken, setAuthToken] = useState(persistedToken);
  const [userInfo, setUserInfo] = useState(null);

  function updateAuthToken(token) {
    setAuthToken(token);
    if (token) {
      localstorageUtils.save("token", token);
    } else {
      localstorageUtils.remove("token");
    }
  }

  function updateUserInfo(userInfo) {
    setUserInfo(userInfo);
  }

  function logout() {
    setAuthToken(null);
    setUserInfo(null);

    localstorageUtils.remove("token");
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken: updateAuthToken,
        userInfo,
        setUserInfo: updateUserInfo,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
