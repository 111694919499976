import React from "react";
import { Flex, Box, Skeleton } from "@chakra-ui/react";

export default function CategoryMenuSkeleton() {
  return (
    <Box
      w={{ sm: "100%", md: "100%", lg: "80%", xl: "80%" }}
      p={{ sm: 6, md: 12 }}
    >
      <Skeleton h={12} rounded="lg" />
      <Skeleton h={12} rounded="lg" mt={6} />

      <Flex direction="row" wrap="wrap" w="100%" justify="space-around" mt={12}>
        {Array.from({ length: 8 }, (_, index) => index + 1).map((item) => (
          <Skeleton
            key={item}
            h={{ sm: 156, md: 156, lg: "256", xl: 256 }}
            w={{ sm: "40%", md: "33%", lg: "20%", xl: "20%" }}
            rounded="lg"
            m={{ sm: 2, md: 6, lg: 6, xl: 6 }}
          />
        ))}
      </Flex>
    </Box>
  );
}
