import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Icon,
  Tooltip,
  Link as ChakraLink,
  useColorModeValue,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

export default function NavItem({ title, to, icon, closeMenu }) {
  const currentPath = useLocation()?.pathname;
  const isActivePath = useMemo(() => currentPath === to, [to, currentPath]);

  const bgColor = useColorModeValue("purple.100", "purple.900");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const color = useColorModeValue("gray.500", "gray.400");
  const textColor = useColorModeValue("black", "white");
  const activeColor = useColorModeValue("purple.500", "purple.300");

  return (
    <motion.li
      variants={{
        open: {
          x: 0,
          opacity: 1,
          transition: {
            x: { duration: 0.5 },
          },
        },
        closed: {
          x: "-6rem",
          opacity: 0,
          transition: {
            x: {},
          },
        },
      }}
      style={{ listStyle: "none" }}
    >
      <Box
        as={Tooltip}
        label={title}
        aria-label={title}
        placement="right"
        hasArrow
      >
        <ChakraLink
          as={Link}
          to={to}
          position="relative"
          zIndex={0}
          display="block"
          textDecoration="none"
          color={isActivePath ? activeColor : color}
          cursor="pointer"
          _hover={{
            bg: isActivePath ? bgColor : hoverBg,
            color: activeColor,
          }}
          fontSize={{ base: "1rem", lg: "1.5rem" }}
          fontWeight="500"
          p={3}
          m={3}
          rounded="lg"
          onClick={closeMenu}
        >
          <AnimatePresence>
            {isActivePath && (
              <Box
                as={motion.div}
                animate
                layoutId="activePathBG"
                position="absolute"
                zIndex={-1}
                top={0}
                left={0}
                h="100%"
                w="100%"
                rounded="lg"
                bg={isActivePath ? bgColor : ""}
                boxShadow={isActivePath ? "md" : ""}
              />
            )}
          </AnimatePresence>
          <Flex
            direction="row"
            align="center"
            justify={{ base: "start", lg: "center" }}
            w="100%"
          >
            <Box>
              <Icon lineHeight={0} as={icon} mr={{ base: 1, lg: 0 }} />
            </Box>
            <Text
              ml={2}
              display={{ base: "", lg: "none" }}
              color={textColor}
              isTruncated
            >
              {title}
            </Text>
          </Flex>
        </ChakraLink>
      </Box>
    </motion.li>
  );
}
