import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";

import { AuthContext } from "../contexts/auth.context";
import routes from ".";

export default function RouteSwitch() {
  const { authToken, userInfo } = useContext(AuthContext);

  return (
    <Switch>
      {routes.map((route, idx) => {
        const hasAuthorization = !(route.protected && !authToken);
        const hasPermission =
          route.allowedRoles && route.allowedRoles?.length > 0
            ? route.allowedRoles?.includes(userInfo?.user_type)
            : true;

        const isAllowed = hasAuthorization && hasPermission;

        return (
          <Route
            key={idx}
            path={route.path}
            exact
            render={() => (isAllowed ? route.component : <></>)}
          />
        );
      })}
    </Switch>
  );
}
