import React from "react";
import { Flex, Skeleton, useColorModeValue } from "@chakra-ui/react";

import ProductsSkeleton from "../product/ProductsSkeleton";

export default function CategoryMenuSkeleton() {
  const menuBgColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Flex direction="row" wrap="wrap">
      <Flex
        grow={1}
        direction="column"
        p={6}
        display={{ sm: "none", md: "none", lg: "block", xl: "block" }}
        bg={menuBgColor}
      >
        {Array.from({ length: 5 }, (_, index) => index + 1).map((item) => (
          <Skeleton
            key={item}
            h={16}
            my={6}
            rounded="lg"
            display={{ sm: "none", md: "none", lg: "block", xl: "block" }}
          />
        ))}
      </Flex>

      <ProductsSkeleton />
    </Flex>
  );
}
